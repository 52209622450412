import { render, staticRenderFns } from "./CuratedProjects.vue?vue&type=template&id=4a27a38c&scoped=true&"
import script from "./CuratedProjects.vue?vue&type=script&lang=js&"
export * from "./CuratedProjects.vue?vue&type=script&lang=js&"
import style0 from "./CuratedProjects.vue?vue&type=style&index=0&id=4a27a38c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a27a38c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CuratedProjectCard: require('/tmp/build_3ab6882f/components/CuratedProjectCard.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VDivider,VRow})
