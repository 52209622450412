import { render, staticRenderFns } from "./Hero.vue?vue&type=template&id=4ecab35a&scoped=true&"
import script from "./Hero.vue?vue&type=script&lang=js&"
export * from "./Hero.vue?vue&type=script&lang=js&"
import style0 from "./Hero.vue?vue&type=style&index=0&id=4ecab35a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ecab35a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VideoBackgroundSSR: require('/tmp/build_3ab6882f/components/VideoBackgroundSSR.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VRow})
