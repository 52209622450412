import { render, staticRenderFns } from "./index.vue?vue&type=template&id=37a41e1e&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=37a41e1e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37a41e1e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Hero: require('/tmp/build_3ab6882f/components/Hero.vue').default,FeaturedProject: require('/tmp/build_3ab6882f/components/FeaturedProject.vue').default,CuratedProjects: require('/tmp/build_3ab6882f/components/CuratedProjects.vue').default,FeaturedCreators: require('/tmp/build_3ab6882f/components/FeaturedCreators.vue').default,LandingFooter: require('/tmp/build_3ab6882f/components/LandingFooter.vue').default})
